.boblwld {
  font-weight: bold;
}

/* th {
  font-weight: 400 !important;
  font-family: "Poppins";
} */

.selectedRow {
  background-color: #21a9ec !important;
}

.align-right {
  text-align: right;
}

.loading {
  text-align: -webkit-center;
}

.notfound {
  text-align: center;
  margin: 10px;
}

.m-block {
  display: block !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.w-100 {
  width: 100%;
}

.color-green {
  color: green;
}
.color-red {
  color: red !important;
}

.required-red {
  color: red !important;
}

.swal-wide {
  width: 400px !important;
}

.italic {
  font-style: italic;
}

.select-employee-report {
  font-weight: normal !important;
  color: red !important;
  font-style: italic;
}
/* .modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
} */

/* .fade.show {
  opacity: 1;
}
.modal {
    z-index: 1072;
}
body.modal-open {
    overflow: hidden;
} */

.f-right {
  float: right;
}

.f-left {
  float: left;
}

.b-none {
  border: none;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.mini-logo {
  text-align: center;
  width: 70px;
}

.big-logo {
  text-align: center;
  width: 100px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2899d3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.sidebar {
  overflow-x: hidden;
  /* overflow-y: auto; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#block_container {
  text-align: center;
}
#bloc1,
#bloc2 {
  display: inline;
}

.p-size {
  width: 60px;
}

.ml-15 {
  margin-left: 15px;
}

.navbar-collapse.collapse {
  display: block !important;
  margin-top: -50px !important;
}

/* select padding */
.css-2m8qo4-control {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 36px !important;
}

.css-1y7p5fc-control {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 36px !important;
}

.css-u7jnse-control {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 36px !important;
}

.m-branch {
  /* background-color: red !important; */
  /* color: red; */
  min-width: 250px;
}

.swal-button:focus {
  box-shadow: none;
}

.btn-select {
  padding: 8px 20px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-0 {
  margin-top: 10 !important;
}

.checkbox + .checkbox,
.radio + .radio {
  margin-top: 10px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-10 {
  margin-left: 10px;
}

.m-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-10 {
  padding-right: 10px;
}

.p-5 {
  padding: 5px !important;
}

.h-30 {
  height: 30px !important;
}

.tbl-input {
  border: 1px dotted #535353 !important;
  border-radius: 3px !important;
  padding-left: 5px !important;
  width: 100% !important;
}

.input-wrapper {
  position: relative;
  display: inline-block;
}
.input-wrapper input {
  padding-right: 30px;
  border: 1px dotted #535353 !important;
  border-radius: 3px !important;
  padding-left: 5px !important;
  width: 100% !important;
  font-style: italic;
  font-weight: bolder;
}
.material-icons-input {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #b1b1b1;
  font-size: 20px !important;
}

.attachment {
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
  width: 200px;
  text-align: "center";
  cursor: pointer;
  background-color: beige;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

.assign-exec-ok {
  color: #4caf50;
}
.assign-exec-pending {
  color: #ff9800;
}
.assign-exec-not {
  color: #f44336;
}

.cursor-pointer {
  cursor: pointer !important;
}

.min-width-column {
  min-width: 155px;
}

.nav > li > a {
  padding: 7px 15px !important;
}

.contract-status-1 {
  color: #09f009;
  font-weight: bolder;
}

.contract-status-2 {
  color: #f8571c;
  font-weight: bolder;
}

.contract-status-3 {
  color: #8f8d8d;
  font-weight: bolder;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  padding: 3px !important;
}

.open-blue {
  background-color: #00bcd4 !important;
}

.dark-green {
  background-color: #188305 !important;
}

.select-color-ribbon {
  /* display: inline-block; */
  color: white;
  padding: 8px 8px;
  border-radius: 50%;
  text-align: center;
  float: right;
}

.selected-lang {
  background-color: #2899d3;
  color: white;
  border-radius: 5px;
}

.p-3 {
  padding: 3px;
}

.lang {
  cursor: pointer;
}

.org-name {
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.user-name {
  color: white;
  font-weight: 600;
  /* font-size: 1px; */
}

.user-role {
  color: white;
  font-weight: 600;
  /* font-style: italic; */
}

.tree-view {
  border: 1px solid #e0e0e0;
  padding: 10px;
}

.MuiTreeItem-label {
  font-size: 18px !important;
}

.node-check {
  margin: 7px !important;
}

.tbl-footer {
  border-top: 1px solid #333;
  font-weight: 600;
}

.react-datepicker-wrapper {
  width: 100%;
}

.btn-modal-filter {
  font-size: 12px !important;
  padding: 12px 30px !important;
  line-height: 1em I !important;
  margin-right: 30px !important;
}

.table-max-height {
  /* max-height: 42vh;
  overflow-y: auto; */
}

.table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.z-100 {
  z-index: 100;
}

.salary-box {
  border: 1px solid #c4c4c4;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}

.pre-notify {
  border: none;
  background: white !important;
  font-family: "Poppins";
  color: red;
  word-break: normal;
  word-wrap: normal;
  overflow-wrap: normal;
  white-space: pre-wrap;
}

.profile-icon {
  width: 35px;
  height: 35px;
  /* margin: 10px; */
  /* border: solid 2px; */
  background-color: white;
  /* padding: 4px; */
  align-content: center;
  text-align-last: center;
  border-radius: 3px;
  margin-left: 5px;
  color: #2899d3;
}

table {
  width: 100%;
  /* border-collapse: collapse; Ensures the borders of adjacent cells merge */
}

th,
td {
  /* border: 1px solid rgb(139, 138, 138);  */
  padding: 8px; /* Adds space inside the cells */
  text-align: left; /* Aligns the text to the left */
}

th {
  /* background-color: #f2f2f2;  */
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

.report.table > tbody > tr > td,
.report.table > tbody > tr > th,
.report.table > tfoot > tr > td,
.report.table > tfoot > tr > th,
.report.table > thead > tr > td,
.report.table > thead > tr > th {
  border: 1px solid #ddd !important;
}

.notify-tab-noactive {
  background-color: rgba(200, 200, 200, 0.2) !important;
}

.notify-tab-active {
  background-color: rgba(0, 0, 0, 0.42) !important;
  box-shadow: none !important;
}

.wrap-notify-content {
  white-space: pre-wrap;
}
